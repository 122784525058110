class ZIDXSearchEmbedView implements ZIDXPage {
    constructor() {
    }

    getPageClass() {
        return "zidxSearchEmbedViewContainer";
    }

    getMatchURL() {
        return "/search-embed/";
    }

    render() {
        // console.log("site home");
        window.zidxBase.init(false, true, false, false)
        let loadMoreIntervalId=setInterval(function(){
            if(window.location.href.indexOf("loadMore=1")!=-1){
                let loadMoreButton=ZIDX.$(".zidxLoadMoreButton");
                if(loadMoreButton.length>0) {
                    let rect = loadMoreButton[0].getBoundingClientRect();
                    // scroll to top of rect
                    window.scrollTo(0, rect.top - 200);
                    loadMoreButton.trigger("click");
                    clearInterval(loadMoreIntervalId);
                }
            }
        }, 100);
    }
}